import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const Company = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-company.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      andreas: file(relativePath: { eq: "profiles/andreas-boegemann.jpg" }) {
        ...avatarTraced
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        ...avatarTraced
      }
      marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
        ...avatarTraced
      }
      volker: file(relativePath: { eq: "profiles/volker-schroedter.jpg" }) {
        ...avatarTraced
      }
    }

    fragment avatarTraced on File {
      childImageSharp {
        gatsbyImageData(
          width: 810
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
        )
      }
    }
  `);

  return (
    <Layout lang="en" langPath="nav.company.link">
      <Seo
        title="From the Port of Hamburg into the digital world"
        description="We’ve won the trust of our customers thanks to the hard work of our team over the years. Our erstwhile start-up is now a successful IT business."
        keywords="Company, about us, team, partners, management, history, CMS, DXP, consulting"
      />
      <div className="parallax-translate bg-shape-cross"></div>
      <Hero
        headline="About us"
        subline="We’ve won the trust of our customers thanks to the hard work of our team over the years. Our erstwhile start-up is now a successful IT business."
        section="Company"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Company"
        />
      </Hero>
      <div className="container mb-4 mb-md-6">
        <div className="row mb-4">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">It all began on a Monday</h3>
            <p>
              Monday Consulting was formed in Hamburg on 6 April 2009. Which
              was, of course, a Monday. With our vision of being the best CMS
              service provider guiding our way like a lighthouse, we set out on
              that fateful day filled with inspiration for our work—from the
              Port of Hamburg to the digital world. From the outset, our winning
              formula was our domain expertise plus our straightforward approach
              to collaboration. Our first projects turned into long-standing
              partnerships. Several major commissions followed, which gave us an
              opportunity to deepen our knowledge and, ultimately, establish
              ourselves as experts in the fields of insurance and healthcare.
            </p>
            <Link
              to="/en/references"
              className="btn text-color-white bg-monday-skyblue"
            >
              More about our case studies
            </Link>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">From the Port of Hamburg...</h3>
            <p>
              Our Hanseatic origins are easily discoverable from the location of
              our offices: a step away from St Pauli, with the Fish Market at
              our feet and a view out over the Elbe, Monday is based in the
              heart of Hamburg. Even the building floorplan resembles the
              contours of a ship’s hull. And we have ‘docks’ instead of
              conference rooms in our office space.
            </p>
            <p>
              The way we work also betrays our origins: Monday is a company that
              pays due homage to the traditional commercial values of the
              Hanseatic League. Above all, we honor the values of reliability,
              quality and strong partnerships. For our customers, our word is
              our bond: we want to work together with them to develop
              high-quality solutions that ensure our clients’ long-term success.
              It’s the Monday formula for satisfied customers.
            </p>
            <Link
              to="/kultur"
              className="btn text-color-white bg-monday-skyblue"
            >
              More about our culture
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-4">
            <h3 className="h4">...to the digital world.</h3>
            <p>
              Monday develops content management systems for cross-platform
              media to optimise visitor and customer dialog. We specialize in
              the creation of modern software solutions, drawing on our
              impressive range of competencies in CMS, professional planning and
              dynamic, effective project management. From customer platforms to
              employee portals, content management solutions from Monday get
              information moving, promote dialog, network people and processes,
              and generate valuable insights about the behavior and the needs of
              your target audiences.
            </p>
            <Link
              to="/en/services"
              className="btn text-color-white bg-monday-skyblue"
            >
              More about our services
            </Link>
          </div>
        </div>
      </div>
      <div className="content-container bg-monday-green bg-pattern-vertigo parallax-background">
        <div className="container mb-2 py-5">
          <div className="row text-center text-color-white">
            <div className="col-12 d-flex flex-column align-items-center reveal">
              <h2>Company management</h2>
              <p>
                The Monday team is made up of specialists with a wealth of
                experience gained at various levels in our industries. Common to
                them all is their broad-based know-how in development, creative
                input, integration, consulting and project management. Together,
                Andreas Bögemann, Volker Schrödter, Dirk Schrödter and Marcel
                Scheland make up our management team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container vertical-offset text-center mb-4 mb-md-6">
        <div className="row">
          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.andreas.childImageSharp.gatsbyImageData}
                      alt="Andreas Bögemann"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Andreas Bögemann</h3>
              <p>
                As a Senior HR Manager, he is responsible for the personal
                development of the Monday team. He uses his experience as a
                project manager primarily for our Formcentric product.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.dirk.childImageSharp.gatsbyImageData}
                      alt="Dirk Schrödter"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Dirk Schrödter</h3>
              <p>
                Dirk looks after Sales and Partner Management. As a Senior
                Consultant and Software Architect for CoreMedia and FirstSpirit,
                he is also responsible for our Formcentric product.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.volker.childImageSharp.gatsbyImageData}
                      alt="Volker Schrödter"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Volker Schrödter</h3>
              <p>
                Our growth is naturally dependent on a secure financial base and
                a stable technical infrastructure. All of which is taken care of
                by our experienced Senior Consultant and Software Architect.
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 reveal">
            <div className="p-3 h-100 bg-monday-yellow">
              <div className="w-50 mx-auto mb-3">
                <div className="avatar">
                  <div className="img">
                    <GatsbyImage
                      image={images.marcel.childImageSharp.gatsbyImageData}
                      alt="Marcel Scheland"
                    />
                  </div>
                </div>
              </div>
              <h3 className="h4">Marcel Scheland</h3>
              <p>
                As a Certified Scrum Master and Developer, our CMS expert with
                many years of CoreMedia experience is a walking reference book
                on the agile method. Within the company, Marcel is not only
                responsible for our customer projects but also for Corporate
                Communications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Company;
